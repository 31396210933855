import BaseContainer from '@components/base-container'
import HeaderAlternative from '@components/hero-alternative'
import ReceiptCounterFooter from '@components/receipt-counter-footer'
import { useWindowDimensions } from '@services/hooks'
import { Color } from '@services/styles'
import hero from '@static/images/our-data/hero-joined.png'
import React from 'react'
import { Container } from 'react-bootstrap'
import ImageWithDescription from './section/ImageWithDescription'
import './style.scss'
import { OurDataSectionContent } from './types'

const OurDataPage = () => {
  const { width } = useWindowDimensions()
  const lgWindow = width >= 992
  return (
    <BaseContainer withPadding>
      <div style={{ backgroundColor: Color.WHITE_CREAM }}>
        <HeaderAlternative title={'Our data'} image={hero} />
      </div>
      <section
        className={'pb-5 pt-3'}
        style={{ backgroundColor: Color.WHITE_CREAM }}
      >
        <Container
          className="p-0"
          style={{ backgroundColor: Color.WHITE_CREAM }}
        >
          {OurDataSectionContent.map((i, index) => (
            <ImageWithDescription
              key={index}
              reverse={lgWindow ? index % 2 !== 0 : false}
              LeftComponent={
                <div className="d-flex justify-content-center w-100">
                  <img
                    src={i.image}
                    id={i?.id}
                    className="fit-contain"
                    alt={'start-with-the-truth'}
                    style={{ width: i?.imageWidth }}
                  />
                </div>
              }
              RightComponent={
                <div className={'px-lg-5'}>
                  <h2
                    className="fw-bolder my-3"
                    style={{ color: Color.DARK_BLUE }}
                  >
                    {i?.title}
                  </h2>
                  <p
                    className={`fw-light`}
                    style={{ color: Color.DARK_BLUE, fontSize: '1.1rem' }}
                  >
                    {i.description}
                  </p>
                </div>
              }
            />
          ))}
        </Container>
        {/*{lgWindow && <ArrowComponent from="from" to="to" />}*/}
      </section>

      <ReceiptCounterFooter sectionBackGround={Color.WHITE_CREAM} />
    </BaseContainer>
  )
}

export default OurDataPage
