import React from 'react'
import { Col, Row } from 'react-bootstrap'

interface props {
  justifyContain?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  LeftComponent: React.ReactNode
  RightComponent: React.ReactNode
  reverse: boolean
}

const ImageWithDescription = ({
  LeftComponent,
  RightComponent,
  reverse,
  justifyContain
}: props) => {
  return (
    <Row className="w-100 m-0 mb-5">
      <Col
        lg={6}
        md={12}
        className={`d-flex align-items-center justify-content-${
          justifyContain || 'center'
        }`}
      >
        {!reverse ? LeftComponent : RightComponent}
      </Col>
      <Col
        lg={6}
        md={12}
        className={`d-flex align-items-center justify-content-${
          justifyContain || 'center'
        }`}
      >
        {!reverse ? RightComponent : LeftComponent}
      </Col>
    </Row>
  )
}

export default ImageWithDescription
