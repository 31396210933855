import first from '@static/images/our-data/first.png'
import fourth from '@static/images/our-data/fourth.png'
import second from '@static/images/our-data/second.png'
import third from '@static/images/our-data/third.png'

export const OurDataSectionContent = [
  {
    id: 'from',
    title: 'Start with the truth',
    description:
      'We start with the truth, eliminate all forms of manipulation' +
      ' from our results, and you have a clear picture of who your customers really are.  We start with actual transaction data from millions of receipts and then eliminate all forms of manipulation from the formula',
    image: first,
    imageWidth: '70%'
  },
  {
    id: 'to',
    title: null,
    description:
      'We do not outsource the collection of survey results to third' +
      ' party platforms. This means we have complete control over the data, and complete responsibility for its integrity. ',
    image: second,
    imageWidth: '70%'
  },
  {
    id: null,
    title: 'Our surveys are 100% verified by receipt upload data',
    description:
      'Our practice is unique in that we directly collect survey results' +
      " from your actual customers. This means no matter how big or small your cohort is you'll have accurate, real data. This also removes all forms of manipulation such as fraud, bias and bots from your data set.",
    image: third,
    imageWidth: '70%'
  },
  {
    id: null,
    title: null,
    description:
      'The receipts upload feature is our most important and unique' +
      ' feature. It allows us to ensure that each answer is an actual purchase and eliminates bot and click farm',
    image: fourth,
    imageWidth: '70%'
  }
]
